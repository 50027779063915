body {
  background-image: 
    radial-gradient(circle, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 70%, rgba(0, 0, 0, 1) 100%),
    url('../images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  margin: 0;
  transition: background-position 0.1s ease; /* Smooth transition for parallax effect */
}

.App {
  text-align: center;
}

input {
  background-color: rgba(255, 255, 255, 0.2);
  color: #e5e7eb !important;
  font-size: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.75rem;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #e5e7eb;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

li:last-child {
  border-bottom: none;
}

.fade-in {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInEase 0.5s ease-in-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInEase 0.5s ease-in-out 0.5s forwards; /* Add a 0.25s delay */
}

@keyframes fadeInEase {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ease-out {
  animation: easeOut 0.25s ease-in-out backwards !important;
}

@keyframes easeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/* Vertical Banner Ad */
.vertical-banner-ad-left {
  position: fixed;
  top: 0;
  left: 0;
  width: 160px; /* Standard width for vertical ads */
  height: 100vh; /* Full height of the viewport */
  background-color: #f9f9f9; /* Optional: Add a background color */
  z-index: 1000; /* Ensure it stays above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
}

/* Right Vertical Banner Ad */
.vertical-banner-ad-right {
  position: fixed;
  top: 0;
  right: 0; /* Position it on the right side */
  width: 160px; /* Standard width for vertical ads */
  height: 100vh; /* Full height of the viewport */
  background-color: #f9f9f9; /* Optional: Add a background color */
  z-index: 1000; /* Ensure it stays above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
}

/* Adjust Main Content to Avoid Overlap */
.main-content {
  margin-left: 160px; /* Same width as the left vertical banner ad */
  margin-right: 160px; /* Same width as the right vertical banner ad */
}

/* Adjust the "Support WherePlay" Button */
.support-button {
  position: absolute;
  top: 16px;
  right: 176px; /* Add padding to avoid overlap with the right ad */
  z-index: 1100; /* Ensure it stays above other elements */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: #9f7aea; /* Purple color */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}